import React from 'react';
import Carousel from '../components/Carousel';  

function Features() {
  return (
    <div className="text-center">
      <h1 className="text-4xl font-bold my-6">Features</h1>
      <p className="mb-8">Explore the features of our platform designed to enhance your Agile experience.</p>
      
      {/* Render the Carousel component */}
      <Carousel />
    </div>
  );
}

export default Features;
