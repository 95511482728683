import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import config from '../config';

function PlanItPoker() {
  const [rooms, setRooms] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [roomName, setRoomName] = useState('');
  const [deckType, setDeckType] = useState('Scrum');
  const [selectedCards, setSelectedCards] = useState([]);
  const [options, setOptions] = useState({
    enterStories: true,
    confirmSkip: true,
    observerView: true,
    revealVotes: true,
    allowChangeVote: false,
    useTimer: false,
  });
  const navigate = useNavigate();
  const { userId } = useAuth();

  const cardValues = useMemo(() => ({
    Scrum: [0, '½', 1, 2, 3, 5, 8, 13, 20, 40, 100, '?', 'Coffee'],
    Fibonacci: [0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, '?', 'Coffee'],
    Sequential: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, '?', 'Coffee'],
    Hours: [0, 1, 2, 3, 4, 6, 8, 12, 16, 24, 32, 40, '?', 'Coffee'],
    'T-shirt': ['XS', 'S', 'M', 'L', 'XL', 'XXL', '?', 'Coffee'],
  }), []);

  useEffect(() => {
    setSelectedCards(cardValues[deckType]);
  }, [deckType, cardValues]);

  const fetchRooms = useCallback(async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/api/planit-poker/${userId}/rooms`);
      setRooms(response.data);
    } catch (error) {
      console.error("Error fetching PlanIt Poker rooms:", error);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchRooms();
    }
  }, [userId, fetchRooms]);

  const handleOptionChange = (option) => {
    setOptions((prev) => ({
      ...prev,
      [option]: !prev[option],
    }));
  };

  const handleCreateRoom = async () => {
    try {
      const roomData = {
        name: roomName,
        deckType,
        selectedCards,
        options,
        userId,
      };
      const response = await axios.post(`${config.apiBaseUrl}/api/planit-poker/create`, roomData);
      const createdRoom = response.data;
      navigate(`/planit-poker-room/${createdRoom.room.roomId}`);
      setShowModal(false);
      setRoomName('');
    } catch (error) {
      console.error('Error creating PlanIt Poker room:', error);
    }
  };

  return (
    <div className="p-4 text-[#ffffff]">
      <h1 className="text-2xl font-bold mb-4">PlanIt Poker Rooms</h1>

      <button onClick={() => setShowModal(true)} className="bg-[#00bcd4] text-[#121212] px-4 py-2 rounded">
        Create Room
      </button>

      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-2">Room History</h2>
        {rooms.length > 0 ? (
          <ul className="bg-[#1f2937] shadow-md p-4 rounded">
            {rooms.map((room) => (
              <li key={room._id} className="border-b border-[#ffd600] p-2">
                <Link 
                  to={`/planit-poker-roomhistory/${room.roomId}`}
                  className="text-[#00bcd4] hover:underline"
                >
                  <span className="font-bold">{room.name}</span> -{' '}
                  <span>{new Date(room.createdAt).toLocaleDateString()}</span>
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p>No rooms created yet.</p>
        )}
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-[#121212] text-[#ffffff] p-6 rounded shadow-lg max-w-md w-full">
            <h2 className="text-lg font-semibold mb-4">Create PlanIt Poker Room</h2>

            <input
              type="text"
              placeholder="Room Name"
              value={roomName}
              onChange={(e) => setRoomName(e.target.value)}
              className="border border-[#ffd600] p-2 w-full mb-4 bg-[#1f2937] text-[#ffffff]"
            />

            <label className="block text-sm mb-2">Deck Type</label>
            <select
              value={deckType}
              onChange={(e) => setDeckType(e.target.value)}
              className="border border-[#ffd600] p-2 w-full mb-4 bg-[#1f2937] text-[#ffffff]"
            >
              {Object.keys(cardValues).map((type) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>

            <div className="mb-4">
              <label className="block text-sm mb-2">Customize cards values</label>
              <div className="grid grid-cols-4 gap-2">
                {selectedCards.map((card) => (
                  <label key={card} className="flex items-center">
                    <input
                      type="checkbox"
                      checked
                      readOnly
                    />
                    <span className="ml-2">{card}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="mb-4">
              {[
                { label: 'Do you want to enter stories in this room?', option: 'enterStories' },
                { label: 'Request confirmation when skipping stories?', option: 'confirmSkip' },
                { label: 'Do you want observers to see other players voting in real time?', option: 'observerView' },
                { label: 'Do you want to reveal individual votes when voting completed?', option: 'revealVotes' },
                { label: 'Allow players to change vote after scores shown?', option: 'allowChangeVote' },
                { label: 'Do you want to use a countdown timer?', option: 'useTimer' },
              ].map(({ label, option }) => (
                <div key={option} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    checked={options[option]}
                    onChange={() => handleOptionChange(option)}
                  />
                  <span className="ml-2">{label}</span>
                </div>
              ))}
            </div>

            <div className="flex justify-end mt-4">
              <button
                onClick={handleCreateRoom}
                className="bg-[#00bcd4] text-[#121212] px-4 py-2 rounded mr-2"
              >
                Create
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="bg-[#e91e63] text-[#ffffff] px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PlanItPoker;
