import React, { useState, useEffect, useCallback } from 'react';

function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Provided list of images
  const images = [
    'https://cdn.pixabay.com/photo/2022/09/08/11/27/project-management-7440746_640.jpg',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsOxmrQbIuVfYoWwm5sp0hehj4J7GtdOOiNg&s',
    'https://cdn.pixabay.com/photo/2022/09/08/11/27/project-management-7440746_640.jpg',
    'https://thumbs.dreamstime.com/b/project-management-ideas-development-graph-concept-project-management-ideas-development-graph-concept-doodle-city-background-110344299.jpg',
    'https://media.istockphoto.com/id/1411195926/photo/project-manager-working-on-laptop-and-updating-tasks-and-milestones-progress-planning-with.jpg?s=612x612&w=0&k=20&c=5A0CEsRbIrgnci0Q7LSxbrUZ1pliXy8C04ffpnjnVIw='
  ];

  // Function to move to the next image
  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  }, [images.length]);

  // Function to move to the previous image
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  // Auto-scroll effect using useEffect
  useEffect(() => {
    const interval = setInterval(handleNext, 3000); // Change slide every 3 seconds

    // Clear interval on component unmount or user interaction
    return () => clearInterval(interval);
  }, [handleNext]);

  return (
    <div className="relative w-full max-w-6xl mx-auto bg-gray-200 rounded-lg overflow-hidden">
      <div className="relative flex items-center justify-center h-[28rem]">
        {/* Display the current image */}
        <img
          src={images[currentIndex]}
          alt="Carousel Slide"
          className="w-full h-full object-cover transition-all duration-500 ease-in-out"
        />

        {/* Left Arrow */}
        <button
          onClick={handlePrev}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-700 bg-opacity-50 text-white rounded-full p-3 ml-4 focus:outline-none hover:bg-opacity-70"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
          </svg>
          <span className="sr-only">Previous</span>
        </button>

        {/* Right Arrow */}
        <button
          onClick={handleNext}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-700 bg-opacity-50 text-white rounded-full p-3 mr-4 focus:outline-none hover:bg-opacity-70"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
          </svg>
          <span className="sr-only">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Carousel;
