// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth, SignIn } from '@clerk/clerk-react';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Home from './pages/Home';
import PlanitPoker from './pages/PlanitPoker';
import PlanItPokerRoom from './pages/PlanItPokerRoom';
import Retro from './pages/Retro';
import RoomView from './pages/RoomView';
import RoomHistory from './pages/RoomHistory';
import Features from './pages/Features';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound'; // New component for 404 handling
import './tailwind.css';

// Protected Route component to check if user is signed in
function ProtectedRoute({ children }) {
  const { isSignedIn, isLoaded } = useAuth();

  // Show a loading state until authentication status is confirmed
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (isSignedIn) {
    return children;
  }
  return <Navigate to="/sign-in" replace />;
}

function App() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  // Function to toggle sidebar
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  // Dynamic page title based on route
  useEffect(() => {
    const path = window.location.pathname;
    let title = 'AgileHubTools';
    if (path.includes('planit-poker')) title += ' - PlanIt Poker';
    if (path.includes('retro')) title += ' - Retro';
    if (path.includes('features')) title += ' - Features';
    if (path.includes('contact')) title += ' - Contact';
    if (path.includes('roomhistory')) title += ' - Room History';
    if (path.includes('room')) title += ' - Room View';
    document.title = title;
  }, [window.location.pathname]);

  return (
    <Router>
      <div className="App bg-[#121212] text-[#ffffff] min-h-screen">
        {/* Header Component with sidebar toggle */}
        <Header toggleSidebar={toggleSidebar} />
        <div className="flex pt-16">
          {/* Sidebar Component */}
          <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          
          {/* Main Content Area */}
          <div className="flex-1 p-4 sm:ml-64 flex flex-col items-center space-y-8">
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/features" element={<Features />} />
              <Route path="/contact" element={<Contact />} />

              {/* Protected Routes */}
              <Route
                path="/planit-poker"
                element={
                  <ProtectedRoute>
                    <PlanitPoker />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/retro"
                element={
                  <ProtectedRoute>
                    <Retro />
                  </ProtectedRoute>
                }
              />

              {/* PlanIt Poker Room Route */}
              <Route
                path="/planit-poker-room/:roomId"
                element={
                  <ProtectedRoute>
                    <PlanItPokerRoom />
                  </ProtectedRoute>
                }
              />

              {/* Room View Route for conducting a retro in a specific room */}
              <Route
                path="/room/:id"
                element={
                  <ProtectedRoute>
                    <RoomView />
                  </ProtectedRoute>
                }
              />

              {/* Room History Route for viewing a specific room's history */}
              <Route
                path="/roomhistory/:id"
                element={
                  <ProtectedRoute>
                    <RoomHistory />
                  </ProtectedRoute>
                }
              />

              {/* Sign-In Route */}
              <Route path="/sign-in" element={<SignIn />} />

              {/* 404 Not Found Route */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
