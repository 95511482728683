import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

function Sidebar() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="fixed top-0 left-0 h-screen z-40 flex"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Invisible Hover Zone to trigger sidebar */}
      <div
        className="fixed top-0 left-0 h-full w-4 z-40"
        onMouseEnter={() => setIsHovered(true)}
      ></div>

      {/* Logo - Fixed and separate from the sidebar */}
      <div
        className="fixed top-4 left-4 flex items-center space-x-3"
        style={{ zIndex: 50, color: '#0FFCBE' }} // Text color Bright Cyan
      >
        <img src="/Assets/logo.webp" className="h-8" alt="AgileHubTools Logo" />
        <span className="text-2xl font-semibold">AgileHubTools</span>
      </div>

      {/* Sidebar */}
      <aside
        className={`transition-transform duration-300 transform ${
          isHovered ? 'translate-x-0' : '-translate-x-full'
        }`}
        style={{
          backgroundColor: '#121212', // Dark Gray background
          color: '#0FFCBE', // Bright Cyan text color
          width: '250px',
        }}
      >
        <div className="h-full px-3 py-4 overflow-y-auto mt-16">
          <ul className="space-y-2 font-medium">
            <li>
              <NavLink
                to="/"
                className="flex items-center p-2 rounded-lg hover:bg-[#00bcd4] hover:text-[#121212] transition duration-200" // Primary Button Cyan on hover
                style={{ color: '#0FFCBE' }}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/planit-poker"
                className="flex items-center p-2 rounded-lg hover:bg-[#00bcd4] hover:text-[#121212] transition duration-200"
                style={{ color: '#0FFCBE' }}
              >
                Planit Poker
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/retro"
                className="flex items-center p-2 rounded-lg hover:bg-[#00bcd4] hover:text-[#121212] transition duration-200"
                style={{ color: '#0FFCBE' }}
              >
                Retro
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
}

export default Sidebar;
  