import React, { useState } from 'react';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Need to add backend logic to send the form data
    setSubmitted(true);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#121212] text-[#ffffff]">
      <h1 className="text-3xl font-bold mb-6 text-[#ffd600]">Contact Us</h1>
      <p className="mb-8 text-[#ffffff]">
        We'd love to hear from you! Please fill out the form below and we'll get in touch with you soon.
      </p>

      {submitted ? (
        <p className="text-lg font-semibold text-[#00bcd4]">
          Thank you for reaching out! We will get back to you soon.
        </p>
      ) : (
        <form onSubmit={handleSubmit} className="w-full max-w-md bg-[#1f2937] rounded-lg shadow-lg p-8 text-[#ffffff]">
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2 text-[#ffd600]" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded-lg text-[#121212] placeholder-[#121212] border-[#ffd600]"
              placeholder="Your Name"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2 text-[#ffd600]" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded-lg text-[#121212] placeholder-[#121212] border-[#ffd600]"
              placeholder="Your Email"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2 text-[#ffd600]" htmlFor="subject">
              Subject
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg text-[#121212] placeholder-[#121212] border-[#ffd600]"
              placeholder="Subject"
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-bold mb-2 text-[#ffd600]" htmlFor="message">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded-lg text-[#121212] placeholder-[#121212] border-[#ffd600] h-32 resize-none"
              placeholder="Your Message"
            ></textarea>
          </div>

          <button
            type="submit"
            className="w-full bg-[#00bcd4] text-[#121212] py-2 px-4 rounded-lg font-semibold hover:bg-[#e91e63] transition duration-200"
          >
            Send Message
          </button>
        </form>
      )}
    </div>
  );
}

export default Contact;
