import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import axios from 'axios';

function PlanItPokerRoom() {
  const { roomId } = useParams();
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [stories, setStories] = useState([]);
  const [newStoryTitle, setNewStoryTitle] = useState('');
  const [selectedVote, setSelectedVote] = useState(null);
  const [currentStoryId, setCurrentStoryId] = useState(null);
  const [votes, setVotes] = useState([]);
  const [allVoted, setAllVoted] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const inviteLink = `http://localhost:3000/planit-poker-room/${roomId}`;

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get(`http://localhost:7300/api/planit-poker/rooms/${roomId}/users`);
      setUsers(response.data.users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, [roomId]);

  const fetchStories = useCallback(async () => {
    try {
      const response = await axios.get(`http://localhost:7300/api/planit-poker/rooms/${roomId}/stories`);
      setStories(response.data.stories);
    } catch (error) {
      console.error("Error fetching stories:", error);
    }
  }, [roomId]);

  const fetchVotes = useCallback(async (storyId) => {
    try {
      const response = await axios.get(`http://localhost:7300/api/planit-poker/${roomId}/stories/${storyId}/votes`);
      setVotes(response.data.votes);

      // Automatically reveal votes if all users have voted
      if (response.data.votes.length === users.length) {
        setAllVoted(true);
      } else {
        setAllVoted(false);
      }
    } catch (error) {
      console.error("Error fetching votes:", error);
    }
  }, [roomId, users.length]);

  useEffect(() => {
    fetchUsers();
    fetchStories();
  }, [fetchUsers, fetchStories]);

  useEffect(() => {
    if (currentStoryId) {
      fetchVotes(currentStoryId);
    }
  }, [currentStoryId, fetchVotes]);

  const handleVote = async (vote) => {
    try {
      await axios.post(`http://localhost:7300/api/planit-poker/${roomId}/stories/${currentStoryId}/vote`, {
        userId: user.id,
        vote,
      });
      setSelectedVote(vote);
      fetchVotes(currentStoryId);
    } catch (error) {
      console.error("Error voting:", error);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteLink);
    setCopySuccess('Copied!');
    setTimeout(() => setCopySuccess(''), 2000);
  };

  return (
    <div className="flex flex-col items-center text-[#ffffff] p-4 min-h-screen w-full" style={{ backgroundColor: '#1e1e1e' }}>
      <header className="w-full px-6 py-4" style={{ backgroundColor: '#00bcd4' }}>
        <h1 className="text-3xl font-bold text-center text-[#ffffff]">PlanIt Poker</h1>
      </header>

      <div className="flex w-full mt-6 px-6 space-x-6">
        {/* Left Section for Cards with Dynamic Grid */}
        <div className="flex-1 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
          {[0, '½', 1, 2, 3, 5, 8, 13, 20, 40, 100, '?', 'C'].map((card, index) => (
            <div
              key={index}
              className={`flex items-center justify-center h-24 rounded-lg text-2xl cursor-pointer ${
                selectedVote === card ? 'bg-[#ffd600]' : 'bg-[#1e1e1e]'
              }`}
              style={{
                border: `2px solid #ffd600`,
                color: '#ffffff',
              }}
              onClick={() => handleVote(card)}
            >
              {card}
            </div>
          ))}
        </div>

        {/* Right Section - Controls */}
        <div className="w-80 p-4 rounded-lg shadow-lg" style={{ backgroundColor: '#1e1e1e', border: `2px solid #ffd600` }}>
          <h2 className="text-xl mb-4 text-center">Click "Start" to begin voting</h2>
          <button className="w-full text-white py-2 rounded mb-4" style={{ backgroundColor: '#e91e63' }}>Start</button>
          
          <h3 className="text-lg font-semibold">Players:</h3>
          <div className="mt-2 space-y-2">
            {users.map((roomUser, index) => (
              <div key={index} className="flex items-center space-x-2">
                <span className="bg-green-500 w-3 h-3 rounded-full"></span>
                <span>{roomUser.name}</span>
                <span className="ml-auto text-sm text-gray-400">00:00:00</span>
              </div>
            ))}
          </div>

          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-2">Invite a teammate:</h3>
            <div className="flex items-center">
              <input
                type="text"
                readOnly
                value={inviteLink}
                className="p-2 flex-grow text-center"
                style={{
                  backgroundColor: '#1e1e1e',
                  color: '#ffffff',
                  border: `2px solid #ffd600`,
                }}
              />
              <button
                onClick={copyToClipboard}
                className="px-4 py-2 rounded ml-2 text-white"
                style={{ backgroundColor: '#e91e63' }}
              >
                Copy URL
              </button>
            </div>
            {copySuccess && <p className="text-green-500 mt-2">{copySuccess}</p>}
          </div>
        </div>
      </div>

      {/* Bottom Section - Story Management */}
      <div className="w-full mt-8 p-4 rounded-lg shadow-lg" style={{ backgroundColor: '#1e1e1e', border: `2px solid #ffd600` }}>
        <div className="flex justify-between mb-4">
          <div className="text-sm">
            <button className="mr-4" style={{ color: '#ffd600' }}>Active Stories (1)</button>
            <button className="mr-4" style={{ color: '#ffd600' }}>Completed Stories (0)</button>
            <button style={{ color: '#ffd600' }}>All Stories (1)</button>
          </div>
          <div className="text-sm">
            <button className="mr-4" style={{ color: '#ffd600' }}>+ New</button>
            <button style={{ color: '#ffd600' }}>Settings</button>
          </div>
        </div>
        <p className="text-center text-gray-400">You don't have stories yet, create one!</p>
      </div>

      {/* Display Votes */}
      {allVoted && (
        <div className="w-full mt-4 p-4 rounded-lg shadow-lg" style={{ backgroundColor: '#1e1e1e', border: `2px solid #ffd600` }}>
          <h3 className="text-xl font-semibold text-center text-[#ffd600] mb-4">Votes</h3>
          <ul className="space-y-2">
            {votes.map((vote, index) => (
              <li key={index} className="text-center">
                {vote.userId}: {vote.vote}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default PlanItPokerRoom;
