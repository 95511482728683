import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import config from '../config';

// Constants
const RETRO_STYLES = {
  'Start-Stop-Continue': 'Start-Stop-Continue',
  'Mad-Sad-Glad': 'Mad-Sad-Glad',
  '4Ls': '4Ls',
};

const DEFAULT_RETRO_STYLE = RETRO_STYLES['Start-Stop-Continue'];

// API service
const api = axios.create({
  baseURL: config.apiBaseUrl,
  withCredentials: true,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

function Retro() {
  const [rooms, setRooms] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    roomName: '',
    isAnonymous: false,
    retroStyle: DEFAULT_RETRO_STYLE,
  });

  const navigate = useNavigate();
  const { userId } = useAuth();

  // Fetch rooms created by the user
  const fetchRooms = useCallback(async () => {
    if (!userId) {
      console.warn('User authentication required');
      setError('User authentication required');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await api.get(`/retros/${userId}/rooms`);
      setRooms(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setRooms([]);
      } else {
        setError(handleAxiosError(error));
      }
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchRooms();
    }
  }, [userId, fetchRooms]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleCreateRoom = async (e) => {
    e.preventDefault();
    if (!userId) {
      setError('User authentication required');
      return;
    }

    if (!formData.roomName.trim()) {
      setError('Room name is required');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const roomData = {
        name: formData.roomName,
        isAnonymous: formData.isAnonymous,
        retroStyle: formData.retroStyle,
        userId,
      };

      const response = await api.post('/retros/create', roomData);

      const { accessToken } = response.data.room;

      navigate(`/room/${accessToken}`, { state: { isReadOnly: false }, replace: true });
      resetForm();
    } catch (error) {
      setError(handleAxiosError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setShowModal(false);
    setFormData({
      roomName: '',
      isAnonymous: false,
      retroStyle: DEFAULT_RETRO_STYLE,
    });
    setError(null);
  };

  const handleAxiosError = (error) => {
    if (error.response) {
      return error.response.data.message || `Error: ${error.response.status}`;
    }
    if (error.request) {
      return 'No response from server. Please check your connection.';
    }
    return error.message || 'An unexpected error occurred';
  };

  const RoomList = () => (
    <div className="mt-6">
      <h2 className="text-xl font-semibold mb-2">Room History</h2>
      {rooms.length > 0 ? (
        <ul className="bg-[#1f2937] shadow-md p-4 rounded">
          {rooms.map((room) => (
            <li key={room.roomId} className="border-b border-[#ffd600] p-2">
              <Link to={`/roomhistory/${room.accessToken}`} className="text-[#00bcd4] hover:underline">
                <span>{room.name}</span>
                <span>{new Date(room.createdAt).toLocaleDateString()}</span>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <p>No rooms created yet.</p>
      )}
    </div>
  );

  const Modal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-[#121212] text-[#ffffff] p-6 rounded">
        <h2>Create Room</h2>
        {error && <p className="text-red-500">{error}</p>}
        <form onSubmit={handleCreateRoom}>
          <input
            type="text"
            name="roomName"
            value={formData.roomName}
            onChange={handleInputChange}
            placeholder="Enter room name"
            className="border border-gray-300 rounded p-2 w-full mb-2 text-black"
            autoComplete="off" // Added to prevent any input issues
            required
          />
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="isAnonymous"
              checked={formData.isAnonymous}
              onChange={handleInputChange}
            />
            <span>Anonymous</span>
          </label>
          <select
            name="retroStyle"
            value={formData.retroStyle}
            onChange={handleInputChange}
            className="border border-gray-300 rounded p-2 w-full mb-4 text-black"
          >
            {Object.entries(RETRO_STYLES).map(([key, value]) => (
              <option key={key} value={value}>
                {value}
              </option>
            ))}
          </select>
          <button
            type="submit"
            disabled={isLoading}
            className="bg-[#00bcd4] text-white rounded px-4 py-2"
          >
            {isLoading ? 'Creating...' : 'Create'}
          </button>
        </form>
      </div>
    </div>
  );

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Retro Rooms</h1>
      <button
        onClick={() => setShowModal(true)}
        className="bg-[#00bcd4] text-white rounded px-4 py-2"
      >
        Create Room
      </button>
      {error && <p className="text-red-500">{error}</p>}
      <RoomList />
      {showModal && <Modal />}
    </div>
  );
}

export default Retro;
