import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../config'; // Import the config file for API base URL
import './RoomHistory.css';

function RoomHistory() {
  const { id } = useParams(); // AccessToken will be passed here
  const [columns, setColumns] = useState([]); // Holds dynamic columns based on retroStyle
  const [feedbackItems, setFeedbackItems] = useState([]); // Holds feedback for each column
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [room, setRoom] = useState(null); // To store room details
  const [error, setError] = useState(null); // Error handling

  // Define retroStyles as in RoomView.js
  const retroStyles = {
    'Mad-Sad-Glad': [
      { id: 'mad', title: 'Mad' },
      { id: 'sad', title: 'Sad' },
      { id: 'glad', title: 'Glad' },
    ],
    'Start-Stop-Continue': [
      { id: 'start', title: 'Start' },
      { id: 'stop', title: 'Stop' },
      { id: 'continue', title: 'Continue' },
    ],
    // Add more styles if needed
    'Custom': [
      // Define custom columns if needed
    ],
  };

  useEffect(() => {
    const fetchRoomHistory = async () => {
      try {
        setIsLoading(true);
        const roomResponse = await axios.get(`/retros/access/${id}`, {
          baseURL: config.apiBaseUrl, // Validate accessToken
        });
        setRoom(roomResponse.data); // Room is valid

        // Set columns based on retroStyle
        const selectedColumns = retroStyles[roomResponse.data.retroStyle] || retroStyles['Mad-Sad-Glad'];
        setColumns(selectedColumns);

        const feedbackResponse = await axios.get(`/retros/access/${id}/feedback`, {
          baseURL: config.apiBaseUrl,
        });
        setFeedbackItems(feedbackResponse.data);

        // No need to adjust columns dynamically based on feedback anymore
      } catch (error) {
        console.error('Error fetching room history:', error);
        setError(
          error.response?.data?.message || 'Failed to load room history'
        );
        setRoom(null); // Invalid or unauthorized access
      } finally {
        setIsLoading(false);
      }
    };

    fetchRoomHistory();
  }, [id]);

  if (isLoading) {
    return (
      <div className="container">
        <h1>Loading Room History...</h1>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container">
        <h1>Error Loading Room History</h1>
        <p>{error}</p>
      </div>
    );
  }

  if (!room) {
    return (
      <div className="container">
        <h1>Unauthorized or Room Not Found</h1>
        <p>You do not have permission to access this room's history.</p>
      </div>
    );
  }

  return (
    <div className="container">
      <h1 className="room-header">Room History: {room.name}</h1>
      <div className="column-container">
        {columns.map((column) => (
          <div key={column.id} className="column">
            <h2>{column.title}</h2>
            {feedbackItems
              .filter((item) => item.column === column.id)
              .map((item) => (
                <div key={item._id} className="feedback-item">
                  <p>{item.content}</p>
                </div>
              ))}
            {feedbackItems.filter((item) => item.column === column.id).length === 0 && (
              <p className="no-feedback">No feedback available for this column.</p>
            )}
          </div>
        ))}
      </div>
      <p className="view-only-message">This room is in read-only mode.</p>
    </div>
  );
}

export default RoomHistory;
