// CountdownTimer.js
import React, { useState, useEffect } from 'react';
import './CountdownTimer.css';

function CountdownTimer() {
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [countdown, setCountdown] = useState({ hours: 0, minutes: 0, seconds: 0 });

  // Start the countdown when totalSeconds changes
  useEffect(() => {
    if (totalSeconds <= 0) return;
    const interval = setInterval(() => {
      setTotalSeconds((prev) => {
        if (prev <= 0) {
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [totalSeconds]);

  // Update countdown display every second
  useEffect(() => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    setCountdown({ hours, minutes, seconds });
  }, [totalSeconds]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCountdown((prev) => ({ ...prev, [name]: value }));
  };

  const handleStartTimer = () => {
    const seconds = parseInt(countdown.hours) * 3600 + parseInt(countdown.minutes) * 60 + parseInt(countdown.seconds);
    setTotalSeconds(seconds);
  };

  return (
    <div className="countdown-timer">
      <h1>Countdown Timer</h1>
      <div className="countdown-input">
        <input type="number" name="hours" placeholder="Hours" value={countdown.hours} onChange={handleInputChange} />
        <input type="number" name="minutes" placeholder="Minutes" value={countdown.minutes} onChange={handleInputChange} />
        <input type="number" name="seconds" placeholder="Seconds" value={countdown.seconds} onChange={handleInputChange} />
        <button onClick={handleStartTimer}>Start Timer</button>
      </div>
      <div className="countdown-display">
        <div className="bloc-time hours">
          <span className="count-title">Hours</span>
          <div className="figure">{String(countdown.hours).padStart(2, '0')}</div>
        </div>
        <div className="bloc-time min">
          <span className="count-title">Minutes</span>
          <div className="figure">{String(countdown.minutes).padStart(2, '0')}</div>
        </div>
        <div className="bloc-time sec">
          <span className="count-title">Seconds</span>
          <div className="figure">{String(countdown.seconds).padStart(2, '0')}</div>
        </div>
      </div>
    </div>
  );
}

export default CountdownTimer;
